import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../../store/actions";
import { isNull, isArrayNullOrEmpty } from "../../../tools";

import ReactTable from "react-table-6";
import Button from "../../../../components/CustomButtons/Button";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import { Assignment } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";

const TabBeneficiarySupplier = function({ beneficiaryCtx, openThirdParty }) {
  var dispatch = useDispatch();
  var beneficiary = beneficiaryCtx.beneficiary;
  var thirdParties = beneficiaryCtx.thirdParties;

  useEffect(() => {
    dispatch(Actions.GetThirdPartiesByBeneficiaryId(beneficiary.thirdPartyIdentifier));
  }, []);

  ///------------------------------
  /// Crée le contenu (les lignes) de la table-react affichant les tiers associés
  ///------------------------------
  function convertDataToReactTable(thirdParties) {
    if (isNull(thirdParties)) return [];

    return thirdParties.map((prop, key) => {
      var thirdParty = prop;
      return {
        identifier: thirdParty.identifier,
        label: thirdParty.longName,
        legalIdentifier: thirdParty.legalIdentifier,
        zipcode: thirdParty.address_ZipCode,
        city: thirdParty.address_City,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                openThirdParty(thirdParty.thirdPartyIdentifier);
              }}
              color="info"
              className="like"
            >
              <Assignment />
            </Button>
          </div>
        )
      };
    });
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Suppliers</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={convertDataToReactTable(thirdParties)}
              columns={[
                {
                  accessor: "actions",
                  width: 50,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: <Trans>Identifier</Trans>,
                  accessor: "identifier",
                  filterable: true
                },
                {
                  Header: <Trans>Label</Trans>,
                  accessor: "label",
                  filterable: true,
                  filterMethod: (filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                },
                {
                  Header: <Trans>Legal Identifier</Trans>,
                  accessor: "legalIdentifier",
                  filterable: true,
                  filterMethod: (filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                },
                {
                  Header: <Trans>Zip code</Trans>,
                  accessor: "zipcode",
                  filterable: true,
                  filterMethod: (filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                },
                {
                  Header: <Trans>City</Trans>,
                  accessor: "city",
                  filterable: true,
                  filterMethod: (filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                }
              ]}
              defaultPageSize={5}
              showPaginationBottom={true}
              showPageSizeOptions={false}
              className="-striped -highlight"
              showFilters={true}
              style={ReactTableStyle.main}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    beneficiaryCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].beneficiaryCtx : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: thirdPartyId => {
      dispatch(Actions.OpenThirdParty(thirdPartyId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabBeneficiarySupplier));
