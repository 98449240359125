import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Edit, Delete, Save, Warning } from "@material-ui/icons";
import { Button, Tooltip, Select } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import FieldText from "components/Common/FieldText";
import Selector from "components/Common/Selector";
import { isNullOrEmpty } from "tools";
import {
  JobActivityLevel2,
  LocationActivityType,
  LocationActivityValue,
} from "store/MasterValue/MasterValueTypes";
//lingui
import { Trans } from "@lingui/macro";

const TabSiteActivities = ({ site, setSite, isEditable, defaultLang, masterValues, classes }) => {
  const [errors, setErrors] = useState([]);
  const [state, setState] = useState({
    selectedRow: null,
    currentActivityChar: null,
    activitiesChars: []
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const activityValues = masterValues[JobActivityLevel2].filter(c => c.isEnabled);
  const locationActivityValues = masterValues[LocationActivityValue];
  const locationActivityTypes = masterValues[LocationActivityType];

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      activitiesChars: site.activityChars ? site.activityChars.filter(ac => site.activities.some(sa => sa.activityCode === ac.locationActivityIdentifier)) : [],
      pageIndex: 0
    }));
  }, [site.activityChars]);

  const handleAddRow = () => {
    const newActivityChar = {
      locationActivityIdentifier: "",
      locationActivityTypeCode: "",
      locationActivityValueCode: "",
      text: "",
      rowIndex: 0
    };

    setState(prevState => ({
      ...prevState,

      activitiesChars: [newActivityChar, ...prevState.activitiesChars],
      currentActivityChar: newActivityChar,
      selectedRow: 0,
      pageIndex: 0
    }));
    setErrors([]);
  };
  const allowEditRow = (index) => {
    setState(prevState => ({
      ...prevState,
      currentActivityChar: { ...prevState.activitiesChars[index], rowIndex: index },
      selectedRow: index
    }));
  };
  const handleEditRow = (index, field, value) => {
    setState(prevState => ({
      ...prevState,
      currentActivityChar: {
        ...prevState.currentActivityChar,
        [field]: value,
      },
      activitiesChars: prevState.activitiesChars.map((activityChar, i) => {
        if (i === index) {
          return {
            ...activityChar,
            [field]: value
          };
        }
        else {
          return activityChar;
        }
      }),
    }));
    setErrors([]);
  };

  const handleDeleteRow = (index) => {
    setState(prevState => {
      const updatedActivities = [...prevState.activitiesChars];
      updatedActivities.splice(index, 1);
      setSite(prevSite => ({
        ...prevSite,
        activityChars: updatedActivities
      })
      );
      return {
        ...prevState,
        activitiesChars: updatedActivities,
        currentActivityChar: null,
        selectedRow: null
      };
    });
    setErrors([]);
  };

  const handleSaveRow = (index) => {
    if (!state.currentActivityChar.locationActivityIdentifier || state.currentActivityChar.locationActivityIdentifier.trim() === "") {
      setErrors(prevErrors => [...prevErrors, { code: "Activity_is_required", errorMessage: <Trans>Activity_is_required</Trans> }]);
      return;
    }
    if (!state.currentActivityChar.locationActivityTypeCode || state.currentActivityChar.locationActivityTypeCode.trim() === "") {
      setErrors(prevErrors => [...prevErrors, { code: "Attribute_is_required", errorMessage: <Trans>Attribute_is_required</Trans> }]);
      return;
    }

    const duplicateRow = state.activitiesChars.find((activityChar, i) => {
      return (
        i !== index &&
        activityChar.locationActivityIdentifier === state.currentActivityChar.locationActivityIdentifier &&
        activityChar.locationActivityTypeCode === state.currentActivityChar.locationActivityTypeCode
      );
    });

    if (duplicateRow) {
      setErrors(prevErrors => [...prevErrors, { code: "Duplicate_row_found", errorMessage: <Trans>Duplicate_row_found</Trans> }]);
      return;
    }

    setSite(prevSite => ({
      ...prevSite,
      activityChars: state.activitiesChars,
      selectedRow: null
    }));
    setErrors([]);

    setState(prevState => ({
      ...prevState,
      currentActivityChar: null,
      selectedRow: null
    }));

  };

  const renderActions = (index, isEditable) => {

    if (!isEditable) return null;
    return (
      <div className="actions-left">
        {state.selectedRow === index && (
          <Button
            justIcon
            round
            simple
            color="info"
            onClick={() => handleSaveRow(index)}>
            <Save />
          </Button>
        )}
        {state.selectedRow !== index && (
          <Button
            justIcon
            round
            simple
            color="info"
            onClick={() => allowEditRow(index)} >
            <Edit />
          </Button>
        )}
        <Button
          justIcon
          round
          simple
          onClick={() => handleDeleteRow(index)}>
          <Delete color="secondary" />
        </Button>
      </div>
    );
  };

  const renderValueCell = ({ rowInfo, isEditable }) => {
    const index = rowInfo.index;
    const actChar = state.currentActivityChar && state.currentActivityChar.rowIndex === index ? state.currentActivityChar : state.activitiesChars[index];
    if (actChar.locationActivityTypeCode !== null && actChar.locationActivityTypeCode !== "") {
      if (locationActivityValues &&
        locationActivityValues.length > 0 &&
        locationActivityValues.some(
          (mv) => mv.locationActTypeCode === actChar.locationActivityTypeCode
        )
      ) {
        if (!rowInfo.original.locationActivityValueCode
          && (!rowInfo.original.text || isNullOrEmpty(rowInfo.original.text))
          && (index !== state.selectedRow || !isEditable)) {
          return (<></>);
        } else {
          const value = rowInfo.original.locationActivityValueCode;
          return (
            <MasterValueAutocomplete
              isEditable={index === state.selectedRow && isEditable}
              selectedValue={value ? value : ""}
              mvType={LocationActivityValue}
              mvFilter={(mv) => mv.locationActTypeCode === actChar.locationActivityTypeCode}
              onValueChange={(newValue) => {
                handleEditRow(index, "locationActivityValueCode", newValue);
                handleEditRow(index, "text", "");
              }}
            />
          );
        }
      } else {
        const value = rowInfo.original.text;
        return (
          <FieldText
            value={value}
            onChange={(e) => {
              handleEditRow(index, "text", e.target.value);
              handleEditRow(index, "locationActivityValueCode", null);
              e.target.focus();
            }}
            isEditable={isEditable && index === state.selectedRow}
            autoFocus={index === state.selectedRow && isEditable}
          />
        );
      }
    }
    else
      return (<></>);
  };

  const getItems = (value) => {
    return activityValues
      .filter(av => site.activities.some(sa => sa.activityCode === av.code)
        || av.code === value)
      .sort((a, b) => {
        let ai = site.activities.findIndex(v => v.activityCode === a.code);
        ai = ai === -1 ? site.activities.length + activityValues.findIndex(v => v.code === a.code) : ai;
        let bi = site.activities.findIndex(v => v.activityCode === b.code);
        bi = bi === -1 ? site.activities.length + activityValues.findIndex(v => v.code === b.code) : bi;
        return ai - bi;
      })
      .map(act => { return { id: act.code, name: GetMasterValueLabel(act, defaultLang) } });
  };

  const renderTable = (isEditable) => {
    const columns = [
      {
        Header: <Trans>Actions</Trans>,
        width: 120,
        sortable: false,
        filterable: false,
        Cell: ({ index }) => isEditable ? renderActions(index, isEditable) : null
      },
      {
        Header: <Trans>Activity</Trans>,
        accessor: "locationActivityIdentifier",
        Cell: ({ index, value }) => (
          <Selector
            value={value}
            onChange={(event) => {
              handleEditRow(index, "locationActivityIdentifier", event.target.value);
              event.target.focus();
            }}
            native
            isEditable={isEditable && index === state.selectedRow}
            items={getItems(value)}
            displayedProperties={{
              keyProperty: "id",
              valueProperty: "name"
            }}
          />
        )
      },
      {
        Header: <Trans>Attribut</Trans>,
        accessor: "locationActivityTypeCode",
        Cell: ({ index, value }) => {
          if(index != state.selectedRow)
            return <span>{GetMasterValueLabel(locationActivityTypes.filter(v=>v.code === value)[0], defaultLang)}</span>;
          else return(
          <MasterValueAutocomplete
            isEditable={isEditable && index === state.selectedRow}
            selectedValue={value}
            mvType={LocationActivityType}
            onValueChange={(newValue) => {
              handleEditRow(index, "locationActivityTypeCode", newValue);
              handleEditRow(index, "locationActivityValueCode", "");
              handleEditRow(index, "text", "");
            }}
          />
          )
          }
      },
      {
        Header: <Trans>Value</Trans>,
        accessor: "locationActivityValueCode",
        Cell: (rowInfo) => {
          return renderValueCell({ rowInfo, isEditable });
        }
      }
    ];

    return (
      <ReactTable
        data={state.activitiesChars}
        columns={columns}
        defaultPageSize={5}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
        page={state.pageIndex}
        onPageChange={(pageIndex) => {
          setState(prevState => ({
            ...prevState,
            pageIndex
          }));
        }}

      />
    );
  };


  var errorPart = "";
  if (!!errors && errors.length > 0 && errors.some(e => !isNullOrEmpty(e.errorMessage))) {
    var errorDetails = errors.map(err => {
      if (!isNullOrEmpty(err.errorMessage)) {
        return <li>{err.errorMessage}</li>;
      }
    });

    const open = Boolean(anchorEl);
    errorPart = (
      <div>
        <Typography
          color="error"
          onClick={event => {
            setAnchorEl(event.currentTarget);
          }}
          style={{ cursor: "pointer" }}
        >
          <Warning color="error" />
          &nbsp;
          <Trans>There are some errors</Trans>
        </Typography>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <Typography color="error">
            <ul>{errorDetails}</ul>
          </Typography>
        </Popover>
      </div>
    );
  }

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}>
      {errorPart}
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <GridContainer>
          <GridItem xs={9} sm={9} md={9}>
            <h4>
              <Trans>Activity Characteristic</Trans>
            </h4>
          </GridItem>
          <GridItem xs={3} sm={3} md={3}>
            {isEditable && (
              <Button
                onClick={handleAddRow}
                disabled={!site.activities || site.activities.length === 0 || state.selectedRow != null}
              >
                <Add />
                &nbsp;<Trans>Add</Trans>
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        {renderTable(isEditable)}
      </CardBody>
    </Card>
  );
};
const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = (state) => {
  return {
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(structureStyle)(TabSiteActivities));