import { ApimGet, ApimPost, ApimPostPromise, ApimPut } from "components/Common/ApimSender";
import { isArray, isArrayNullOrEmpty, isNullOrEmpty } from "tools";

export function SiteGet(siteId, callbackFn, errorFn) {
  ApimGet("api/location/" + siteId, c => callbackFn(c), e => errorFn(e));
}

export function SiteGetDetailed(siteId, callbackFn, errorFn) {
  ApimGet("api/location/" + siteId + "/detailed", c => callbackFn(c), e => errorFn(e));
}

export function SiteUpdate(site, callbackFn, errorFn, validationErrorFn) {
  ApimPut(
    "api/location/" + site.location.id,
    site,
    c => callbackFn(c),
    e => errorFn(e),
    e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
  );
}

export function SiteCreate(site, callbackFn, errorFn, validationErrorFn) {
  ApimPost(
    "api/location/",
    site,
    c => callbackFn(c),
    e => errorFn(e),
    e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
  );
}

export function GetCharacteristicValues(locationType, callbackFn, errorFn) {
  ApimGet("api/Location/charTypes/" + locationType, c => callbackFn(c), e => errorFn(e));
}

export function GetSiteHistory(siteId, callbackFn, errorFn) {
  ApimGet("api/location/" + siteId + "/histories", c => callbackFn(c), e => errorFn(e));
}

export function SiteSearch(criterias) {
  const apiCriterias = criterias.searchMode === "Simple" ? ConvertSimpleCriteria(criterias) : ConvertCriteria(criterias);
  return ApimPostPromise("api/Location/Search", apiCriterias);
}

// Convert Criteria To Simple Query String
function ConvertSimpleCriteria(criteria) {
  var queryParams = {};

  if (!isNullOrEmpty(criteria.criteriaFullText)) {
    queryParams.freeSearch = criteria.criteriaFullText;
  }
  if (criteria.criteriaCountry !== null && criteria.criteriaCountry !== "") {
    queryParams.country = criteria.criteriaCountry;
  }
  // force simple search on active sites
  queryParams.statuses = ["ACTIVE"];

  return queryParams;
}

function ConvertCriteria(criteria) {
  var queryParams = {};

  if (!isNullOrEmpty(criteria.criteriaFullText)) {
    queryParams.freeSearch = criteria.criteriaFullText;
  }
  if (!isNullOrEmpty(criteria.criteriaMdmId)) {
    queryParams.mdmId = criteria.criteriaMdmId;
  }
  if (!isNullOrEmpty(criteria.criteriaMdmId)) {
    queryParams.mdmId = criteria.criteriaMdmId;
  }
  if (!isNullOrEmpty(criteria.criteriaName)) {
    queryParams.name = criteria.criteriaName;
  }
  if (!isNullOrEmpty(criteria.criteriaAddress)) {
    queryParams.address = criteria.criteriaAddress;
  }
  if (!isNullOrEmpty(criteria.criteriaCountry)) {
    queryParams.country = criteria.criteriaCountry;
  }
  if (!isNullOrEmpty(criteria.criteriaCountryState)) {
    queryParams.countryState = criteria.criteriaCountryState;
  }
  if (!isNullOrEmpty(criteria.criteriaStructureId)) {
    queryParams.structureIdentifiant = criteria.criteriaStructureId;
  }
  if (!isNullOrEmpty(criteria.criteriaStructureType)) {
    queryParams.structureType = criteria.criteriaStructureType;
  }
  //if (!isNullOrEmpty(criteria.startPage)) {
  //    queryParams.push("StartPage=" + criteria.startPage);
  //}
  //if (!isNullOrEmpty(criteria.pageSize)) {
  //    queryParams.push("PageSize=" + criteria.pageSize);
  //}
  //if (!isNullOrEmpty(criteria.orderProperty)) {
  //    queryParams.push("OrderProperty=" + criteria.orderProperty);
  //}

  if (!isArrayNullOrEmpty(criteria.siteStatuses)) {
    queryParams.statuses = criteria.siteStatuses;
  }

  if (!isArrayNullOrEmpty(criteria.siteNatures)) {
    queryParams.natures = criteria.siteNatures;
  }

  if (criteria.keepSiteNaturesEmpty) {
    queryParams.keepNaturesEmpty = criteria.keepSiteNaturesEmpty;
  }

  if (criteria.siteNatureParentId) {
    queryParams.natureParentId = criteria.siteNatureParentId;
  }

  if (!isArrayNullOrEmpty(criteria.siteTypes)) {
    queryParams.types = criteria.siteTypes;
  }

  return queryParams;
}
